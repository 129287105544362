@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Noto Sans', sans-serif;
}

@media print {
  body * {
    visibility: hidden;
  }
  .invoice-preview, .invoice-preview * {
    visibility: visible;
  }
  .invoice-preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
    overflow: hidden;
  }
  @page {
    size: letter;
    margin: 0.5in;
    margin-top: 1in;
  }
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
}